import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Head from '../components/head';

import styles from './listpage.module.scss';

const StatusPage = ({
  data: {
    allContentfulVehicle: { nodes: allVehicles },
  },
  pageContext: { ordering, status },
}) => {
  let orderedVehicles = [];
  let pageTitle = '';
  let pageDescription = '';
  let infoText = '';

  let inOrder = [];

  ordering.forEach((thisPlate, i) => {
    const match = allVehicles.filter(
      ({ numberPlate }) => thisPlate === numberPlate
    );

    if (match) {
      inOrder.push(...match);
    }

    if (i + 1 >= ordering.length) {
      if (status === 'non-residential') {
        orderedVehicles = inOrder.filter(
          ({ notResident }) => notResident === true
        );
        pageTitle = 'Non-Residential Vehicles';
        pageDescription =
          'A gallery of known non-resident vehicles using Hollingdean for free parking.';
        infoText =
          'Here, we have collected together some of the known non-resident vechiles that Hollingdean residents have reported as being stored, or using, our streets for free parking.  These are vehicles that should not be here at all, and add to the signficant pressures our residents experience as a result.';
      }

      if (status === 'displaced') {
        orderedVehicles = inOrder.filter(
          ({ expiredPermit }) => expiredPermit === true
        );
        pageTitle = 'Displaced Vehicles';
        pageDescription =
          'A gallery of known displaced vehicles in  Hollingdean, only parking in the area because of the unrestricted parking.';
        infoText =
          'These are vehicles which we can confirm with concrete proof are displaced from adjoining resident parkign zones.  Most commonly, these are known to residents as belonging to non-residents.  They also often still display expired permits in their windscreens.';
      }

      if (status === 'commuter') {
        orderedVehicles = inOrder.filter(
          ({ isCommuter }) => isCommuter === true
        );
        pageTitle = 'Commuter Vehicles';
        pageDescription =
          'A gallery of known commuter vehicles who use Hollingdean as part of their daily commute, blocking the streets for residents.';
        infoText =
          'As one of the last non-permitted zones in Brighton, Hollingdean attacts signficant numbers of commuter vehicles.  These are people and vehicles that arrive in Hollingdean every morning and leave again every morning.  As with other non-residential vehicles, these should not be on our streets at all, and only serve to add to the traffic, polllution, noise, and congestion that our residents struggle with.';
      }

      if (status === 'abandoned') {
        orderedVehicles = inOrder.filter(
          ({ isAbandoned }) => isAbandoned === true
        );
        pageTitle = 'Abandoned Vehicles';
        pageDescription = 'A gallery of abandoned vehicles in Hollingdean.';
        infoText =
          'A signficant problem faced by  Hollingdean residents is abandoned vehicles: these are cars, vans, and lorries that are unceremoniously dumped upon us as long-term storage because it is cheaper for the owner to leave their car here than to deal with it properly.  As MOTs and taxes expire, residents attempt to report them although it often takes many more months for the council or authorities to remove them - if at all.';
      }

      if (status === 'for-sale') {
        orderedVehicles = inOrder.filter(({ isForSale }) => isForSale === true);
        pageTitle = 'Vehicles for Sale';
        pageDescription =
          'A gallery of cars stored in Hollindgean by unscrupulous Facebook Marketplace sellers.';
        infoText =
          'A number of unscrupulous sellers (particularly on Facebook Marketplace) use Hollingdean as a place to store vehicles they have listed for sale.  This is a practice which is illegal, and particularly frustrating when there are so many of them, often unmoved for many weeks at a time.';
      }
    }
  });

  return (
    <Layout>
      <Head
        title={`${pageTitle}`}
        description={pageDescription}
        noIndex={true}
      />

      <main className={styles.home}>
        <h2>{pageTitle}</h2>
        <div className={styles.content}>
          <p>{infoText}</p>
        </div>

        <ul className={styles.grid}>
          {orderedVehicles.map(
            (
              {
                make,
                model,
                colour,
                numberPlate,
                gridImage: {
                  localFile: {
                    childImageSharp: { gatsbyImageData },
                  },
                },
              },
              i
            ) => (
              <li key={`allVehicles-${i}`} className={styles.item}>
                <Link
                  to={`/gallery/${numberPlate
                    .replace(' ', '-')
                    .toLowerCase()}/`}
                  className={styles.link}
                  aria-label={`View ${numberPlate}, a ${colour} ${make} ${model} parked in Hollingdean`}
                >
                  <GatsbyImage
                    image={gatsbyImageData}
                    alt={`${numberPlate}, a ${colour} ${make} ${model} parked in Hollingdean`}
                    placholder="dominantColor"
                    loading="lazy"
                  />
                  <h2 className={styles.numberplate}>{numberPlate}</h2>
                </Link>
              </li>
            )
          )}
        </ul>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query StatusPage {
    allContentfulVehicle {
      nodes {
        id
        numberPlate
        colour
        make
        model
        isForSale
        isCommuter
        isAbandoned
        notResident
        expiredPermit
        gridImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 400
                quality: 40
                placeholder: DOMINANT_COLOR
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`;

export default StatusPage;
